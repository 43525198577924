:root {
  --color-black: #171725;
  --color-white: #ffffff;
  --color-red: #d94325;
  --grayscale1: #f8f8f8;
  --grayscale2: #5a607f;
  --grayscale3: #cecfd7;
  --color-skyblue: #f2f7ff;
  --company-name-color: #7e84a3;
  --color-shadow: #00000029;
  --input-border: #0000001A;
  --text-paragraph: #707070;
  --primary-color: #266fe5;
  --danger-color: #d94325;
}
