/* You can add global styles to this file, and also import other style files */
@import "assets/styles/colors.scss";
@import 'assets/styles/fonts.scss';
@import "assets/styles/variables.scss";

html,
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
